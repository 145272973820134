import { AfterViewInit, Component, ComponentFactory, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { exhaustMap, Subscription } from 'rxjs';
import { ClientManagementService } from 'src/app/shared/services/client-management.service';

import { Company } from '../../api-models/company';
import { GroupPayrollProfile } from '../../api-models/group-payroll-profile';

const DEDUCTION_TYPES = ['PERIODIC', 'FIXED'];
const DEDUCTION_DATES = [...Array(31).keys()].map((x) => x + 1);

@Component({
  selector: 'app-client-setup-wizard',
  templateUrl: './client-setup-wizard.component.html',
  styleUrls: ['./client-setup-wizard.component.css']
})
export class ClientSetupWizardComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly deduction_types = DEDUCTION_TYPES;
  readonly deduction_dates = DEDUCTION_DATES;

  activeIndex: number = 0;
  steps: MenuItem[] = [];
  isFetching: boolean = false;

  payload = {
    data: {
      id: 0,
      action: "create",
      type: "donorgroup",
      name: '',
      environment_code: '',
      companies: [
        { name: '', company_code: '' }
      ],
      grouppayrollprofiles: [
        {
          name: '',
          deduction_type: '',
          pay_group: '',
          pay_frequency: '',
          deduction_interval: null,
          deduction_dates: [],
          date_start_running: '',
          date_holder: null
        }
      ]
    }
  }

  private subscriptions: Subscription[] = [];

  constructor(
    private cmService: ClientManagementService, 
    private router: Router, 
    private messageService: MessageService) {

  }

  ngOnInit(): void {
    this.steps = [
      { 
        label: 'Client Information',
        command: (event: any) => {
          this.activeIndex = 0;
        }
      },
      { 
        label: 'Companies',
        command: (event: any) => {
          this.activeIndex = 1;
        }
      },
      { 
        label: 'Payroll Profiles',
        command: (event: any) => {
          this.activeIndex = 2;
        }
      },
      { 
        label: 'Confirmation',
        command: (event: any) => {
          this.activeIndex = 3;
        }
      },
      { 
        label: 'Finish',
        command: (event: any) => {
          this.activeIndex = 4;
        }
      }
    ]
  }

  ngAfterViewInit(): void {
    // after view processes here...
  }

  companiesValid(companies: Company[]): boolean {
    const offender = companies.find((x) => {
      if(!x.name || !x.company_code) {
        return true;
      } 
      return false;
    });

    return (offender) ? false : true;
  }

  payrollProfilesValid(profiles: GroupPayrollProfile[]): boolean {
    const offender = profiles.find((x) => {
      if( !x.pay_group || !x.pay_frequency || !(x.deduction_interval || x.deduction_dates) || !x.date_start_running ) {
        return true;
      } else {
        return false;
      }
    });

    return (offender) ? false : true;
  }

  addCompany() {
    this.payload.data.companies.push({ name: '', company_code: '' });
  }
  removeCompany(index: number) {
    this.payload.data.companies.splice(index, 1);
  }

  addPayrollProfile() {
    this.payload.data.grouppayrollprofiles.push(
      {
        name: '',
        deduction_type: '',
        pay_group: '',
        pay_frequency: '',
        deduction_interval: null,
        deduction_dates: [],
        date_start_running: '',
        date_holder: null
      }
    );
  }
  removePayrollProfile(index: number) {
    this.payload.data.grouppayrollprofiles.splice(index, 1);
  }
  updateProfileDates(index: number) {
    const prDate: Date | null = this.payload.data.grouppayrollprofiles[index].date_holder;
    if(prDate) {
      this.payload.data.grouppayrollprofiles[index].date_start_running = `${(prDate as Date).getFullYear()}-${(prDate as Date).getMonth()+1}-${(prDate as Date).getDate()}`;
    }
  }

  prevPage() {
    this.activeIndex = this.activeIndex > 0 ? this.activeIndex - 1 : 0;
  }

  nextPage($event: any) {
    if ($event && $event.disabled) {
      return;
    }

    this.activeIndex = this.activeIndex < this.steps.length ? this.activeIndex + 1 : (this.steps.length - 1);
  }

  exitWizard() {
    this.router.navigate(['hud/client-setup']);
  }

  doConfirmThenSubmit() {
    this.isFetching = true;

    // submit then go to next activeIndex 
    this.cmService.doPost<any>(this.payload).subscribe(
      {
        next: (payback) => {
          console.log("Client config submitted successfully!");
          console.log(payback);
          this.messageService.add({severity:'success', summary:'New Client', detail:'Your new client was created and configured successfully'})
          this.nextPage(null);
        },
        error: (err) => {
          console.log("Client config submission error...");
          console.log(err);
          this.messageService.add({severity:'error', summary:'Submission Error', detail: (err.error?.message) ? err.error.message : 'An error occured while submitting your request.  Please try again.'})
          // this.nextPage(null);
        },
        complete: () => {
          this.isFetching = false;
        }
      }
    )
    
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

}
