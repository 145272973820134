<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>G&V Admin Tools | Login</title>
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap"
      rel="stylesheet"
    />
  </head>
  <body>
    <div class="flex items-center min-h-screen p-6 bg-gray-50">
      <div
        class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl"
      >
        <div class="flex flex-col overflow-y-auto md:flex-row">
          <div class="h-32 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              class="object-cover w-full h-full"
              src="assets/img/givful-team.png"
              alt="Office"
            />
          </div>

          
          <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">

            <div class="w-full">

                <h1 class="mb-4 text-xl font-semibold text-gray-700 text-center">G&V Admin Login</h1>

                <input pInputText [(ngModel)]="emailAddress" id="email1" type="text" placeholder="Email address" class="w-full mb-3">
                <input pInputText [(ngModel)]="password" id="password1" type="password" placeholder="Password" class="w-full mb-3" ngClass="{'ng-invalid': this.invalidValues, 'ng-dirty': this.invalidValues }">
                <small *ngIf="this.invalidValues" id="password1-help" class="p-error block pb-4">Email or Password is invalid.</small>
        
                <button pButton label="Login" icon="pi pi-user" class="w-full" (click)="doLogin()"></button>
            </div>
            
          </div>

        </div>
      </div>
    </div>
  </body>
</html>
