import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  
  title = 'gv-admin-utils';
  currentRoute: string = '';

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    
  }

  currentRouteStartsWith(routePathToMatch: string): boolean {
    if (!this.currentRoute) {
      return false;
    }

    return this.currentRoute.startsWith(routePathToMatch);
  }

}
