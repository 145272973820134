import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationDetails, CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';

const USERKEY = 'gv_user';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  userName: string | null = null;
  token: string | null = null;

  constructor(private httpClient: HttpClient) { 
    let storedUser = JSON.parse(localStorage.getItem(USERKEY)!);
    console.log('storedUser...');
    console.log(storedUser);
    if(storedUser) {
      this.userName = storedUser.userName;
      this.token = storedUser.token;
    }
  }

  login(userName: string, password: string): Observable<any> {
    // call the login service
    let authDetails = new AuthenticationDetails({
      Username: userName,
      Password: password
    });
    let userPool = new CognitoUserPool({
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId,
    });
    let cognitoUser = new CognitoUser({
      Username: userName,
      Pool: userPool
    });
    
    return new Observable((subscriber) => {
      this.userName = userName;

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (result) => {
          console.log(result);
          this.token = result.getAccessToken()?.getJwtToken();
          localStorage.setItem(USERKEY, JSON.stringify({userName: this.userName, token: this.token }));
          subscriber.next({userName: this.userName, token: this.token });
        },
        onFailure: (err) => {
          console.log(err);
          subscriber.error(
            {error: { 
              status: 400, 
              message: "Authentication Error: Invalid username or password!"
            }});
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          // delete userAttributes.email_verified;
          console.log('New Password Required...');
          console.log(userAttributes);
        }
      })
      
      return {
        unsubscribe() {
          console.log('login action closed!')
        }
      }

    });
    
  }

  logout() {
    let poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId
    };
    let userPool = new CognitoUserPool(poolData);
    let cognitoUser = userPool.getCurrentUser();

    cognitoUser?.signOut(() => {
      console.log('user signout complete!');
      this.userName = null;
      this.token = null;
      localStorage.removeItem(USERKEY);
    });
  }

  isLoggedIn(): boolean {
    return (this.userName && this.token) ? true : false;
  }
}
