import { Component, Input } from '@angular/core';
import { DonorGroup } from '../../api-models/donor-group';

@Component({
  selector: 'app-donor-group-details',
  templateUrl: './donor-group-details.component.html',
  styleUrls: ['./donor-group-details.component.css']
})
export class DonorGroupDetailsComponent {
  @Input() donorGroup: DonorGroup | null = null;

  constructor() {}
  
}
