<div class-="gv-donor-group-diagram text-center place-content-center">
    <h3 class="mb-4 text-lg font-semibold text-gray-400 text-center w-full">Client Details</h3>

    <ng-container *ngIf="donorGroup" class="w-full">
        <p-card [header]="donorGroup.name" styleClass="text-center max-w-md mx-auto">
            <p class="text-left">
                <span class="inline-block w-44 p-2 text-right font-semibold">ID: </span>
                <span class="inline-block p-2">{{ donorGroup.id ? donorGroup.id : 'n/a' }}</span>
            </p>
            <p class="text-left">
                <span class="inline-block w-44 p-2 text-right font-semibold">Environment Code: </span>
                <span class="inline-block p-2">{{ donorGroup.environment_code }}</span>
            </p>
        </p-card>

        <ng-container *ngIf="donorGroup.companies.length > 0" class="flex items-center">
            <p-divider align="center">
                <span class="p-tag">Companies</span>
            </p-divider>
            <div class="card-tray flex justify-center">
                <p-card *ngFor="let company of donorGroup.companies" styleClass="text-center inline-flex max-w-sm mx-2">
                    <p class="text-left">
                        <span class="inline-block p-2 text-right font-semibold">Name: </span>
                        <span class="inline-block p-2">{{ company.name }}</span>
                    </p>
                    <p class="text-left">
                        <span class="inline-block p-2 text-right font-semibold">Code: </span>
                        <span class="inline-block p-2">{{ company.company_code }}</span>
                    </p>
                </p-card>
            </div>
        </ng-container>

        <ng-container *ngIf="donorGroup.grouppayrollprofiles.length > 0" class="flex items-center">
            <p-divider align="center">
                <span class="p-tag">Payroll Profiles</span>
            </p-divider>
            <div class="card-tray flex justify-center text-sm">
                <p-card *ngFor="let pp of donorGroup.grouppayrollprofiles" styleClass="text-center inline-flex max-w-sm mx-2">
                    <p class="text-left text-sm">
                        <span class="inline-block p-2 font-medium">{{ pp.name }}</span>
                    </p>
                    <p class="text-left text-sm">
                        <span class="inline-block p-2 text-right font-semibold">Pay Group: </span>
                        <span class="inline-block p-2">{{ pp.pay_group }}</span>
                    </p>
                    <p class="text-left text-sm">
                        <span class="inline-block p-2 text-right font-semibold">Pay Frequency: </span>
                        <span class="inline-block p-2">{{ pp.pay_frequency }}</span>
                    </p>
                    <p class="text-left text-sm">
                        <span class="inline-block p-2 text-right font-semibold">Deduction Type: </span>
                        <span class="inline-block p-2">{{ pp.deduction_type }}</span>
                    </p>
                    <p *ngIf="pp.deduction_type.toLowerCase() === 'fixed'" class="text-left text-sm">
                        <span class="inline-block p-2 text-right font-semibold">Deduction Dates: </span>
                        <span class="inline-block p-2">{{ pp.deduction_dates }} of each month</span>
                    </p>
                    <p *ngIf="pp.deduction_type.toLowerCase() === 'periodic'" class="text-left text-sm">
                        <span class="inline-block p-2 text-right font-semibold">Deduction Interval: </span>
                        <span class="inline-block p-2">every {{ pp.deduction_interval }} days</span>
                    </p>
                    <p class="text-left text-sm">
                        <span class="inline-block p-2 text-right font-semibold">Start Date: </span>
                        <span class="inline-block p-2">{{ pp.date_start_running }}</span>
                    </p>
                </p-card>
            </div>
        </ng-container>
    </ng-container>
</div>
