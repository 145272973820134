import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";

import { LoginService } from "../services/login.service";


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor (
        private router: Router,
        private loginService: LoginService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const allowed = this.loginService.isLoggedIn();
        console.log('authguard is allowd...');
        console.log(allowed);
        
        if(!allowed) {
            this.router.navigate(['/login']);
            return false;
        }

        return allowed;
    }
}