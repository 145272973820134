import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ClientManagementService } from 'src/app/shared/services/client-management.service';

import { DonorGroup } from '../../api-models/donor-group';

@Component({
  selector: 'app-donor-groups',
  templateUrl: './donor-groups.component.html',
  styleUrls: ['./donor-groups.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DonorGroupsComponent {

  @Output() clientSelected = new EventEmitter<DonorGroup>();

  clients: DonorGroup [] = [];

  selectedClient: DonorGroup | null = null;

  constructor(private router: Router, private cmService: ClientManagementService) {

  }

  ngOnInit():void {
    this.cmService.bark();

    this.cmService.doGet<{}>().pipe(
      map((res) => {
        return (res as {data: DonorGroup[]}).data;
      })
    ).subscribe({
      next: (data: DonorGroup[]) => {
        // console.log(data);
        this.clients = data;
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  doClientSelected(donorGroup: DonorGroup) {
    // console.log(donorGroup);
    this.clientSelected.emit(donorGroup);
  }

  doAddNewClient() {
    this.router.navigate(['hud/client-setup/new']);
  }

}
