import { Component, OnInit } from '@angular/core';

import { DonorGroup } from '../api-models/donor-group';

@Component({
  selector: 'app-client-setup',
  templateUrl: './client-setup.component.html',
  styleUrls: ['./client-setup.component.css']
})
export class ClientSetupComponent implements OnInit {

  currentDonorGroup: DonorGroup | null = null;

  constructor() {}

  ngOnInit() {

  }

  handleClientSelected(selected: DonorGroup) {
    this.currentDonorGroup = selected;
  }

}
