<div class="container px-6 mx-auto grid">
    <h2
      class="my-6 text-2xl font-semibold text-gray-500"
    >
      Dashboard
    </h2>

    <p-card>
        <ng-template pTemplate="title" class="text-gray-500">
            Welcome, Admin!
        </ng-template>
        <ng-template pTemplate="content">
            <div class="p-fluid formgrid grid">
                <div class="field">
                    <p class="text-center">
                        <img class="m-auto p-2 shadow mb-6" title="mission control" src="assets/img/mission_impossible.gif"/>
                    </p>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="grid grid-nogutter justify-content-end w-full">
                <div class="text-center">

                </div>
            </div>
        </ng-template>
    </p-card>
    
  </div>
