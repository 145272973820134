<div class-=" gv-donor-groups">
    <h3 class="mb-4 text-lg font-semibold text-gray-400" style="text-align:center;">
        Clients
        <span style="display: inline-block;">
            <p-button icon="pi pi-plus" iconPos="left" styleClass="p-button-sm inline-icon-button margin-left" title="Add New Client" (click)="doAddNewClient()"></p-button>
        </span>
    </h3>

    <div class="card">
        <p-table [value]="clients" selectionMode="single" [(selection)]="selectedClient" dataKey="id" [tableStyle]="">
            <ng-template pTemplate="header">
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-client>
                <tr [pSelectableRow]="client" (click)="doClientSelected(client)">
                    <td>{{client.id}}</td>
                    <td>{{client.name}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>
