import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  emailAddress: string = '';
  password: string = '';
  invalidValues: boolean = false;

  constructor(
    private router: Router,
    private loginService: LoginService
  ) {}

  ngOnInit() {

  }

  doLogin() {
    console.log('Logging in...');
    this.loginService.login(this.emailAddress, this.password).pipe(
        catchError(
          (err) => {
            if((err as any).error!.status) {
              console.log('Logged Failed!');
              this.invalidValues = true;
              return throwError(() => err);
            }
            return err;
          }
        )
      ).subscribe(
        (user: any) => {
          console.log(user);
          if(this.loginService.isLoggedIn()) {
            console.log('Logged In!');
            this.invalidValues = false;
            this.router.navigate(['hud/dashboard']);
          }
        }
    )
  }

}
