import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';

import { HudRoutingModule } from './hud-routing.module';
import { HudLayoutComponent } from './hud-layout/hud-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClientSetupComponent } from './client-setup/client-setup.component';
import { ReportsComponent } from './reports/reports.component';
import { DonorGroupsComponent } from './client-setup/donor-groups/donor-groups.component';
import { DonorGroupDetailsComponent } from './client-setup/donor-group-details/donor-group-details.component';
import { ClientSetupWizardComponent } from './client-setup/client-setup-wizard/client-setup-wizard.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    HudLayoutComponent,
    DashboardComponent,
    ClientSetupComponent,
    ReportsComponent,
    DonorGroupsComponent,
    DonorGroupDetailsComponent,
    ClientSetupWizardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    MultiSelectModule,
    TableModule,
    CardModule,
    DividerModule,
    StepsModule,
    ToastModule,
    MessageModule,
    MessagesModule,
    CalendarModule,
    HudRoutingModule
  ],
  providers: [
    MessageService
  ]
})
export class HudModule { }
