import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientManagementService {
  protected accessError = throwError(() => new Error('You do not have access to this API'));
  protected httpHeaderOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-FIO-ADMIN-API-KEY': environment.apiKey
    })
  };

  constructor(private httpClient: HttpClient) { }

  doGet<T>(url?: string): Observable<T> {
    url = url ? `${environment.baseUrl}/${url}` : environment.baseUrl;
    console.log('about to call: ' + url);
    return this.httpClient.get<T>(url, this.httpHeaderOptions);
  }

  doPost<T>(body: any, url?: string): Observable<T> {
    url = url ? `${environment.baseUrl}/${url}` : environment.baseUrl;
    return this.httpClient.post<T>(url, body, this.httpHeaderOptions);
  }

  public bark():void {
    console.log('woof, woof, woof!');
  }
}
