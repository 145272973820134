// This file should be replaced during build ...
// for example for 'staging' this file (environment.ts) should be replaced with 'environment.staging.ts'
// and for 'prod' it should be 'environment.prod.ts'
// add `fileReplacements` to your `angular.json` file

const VERSION = 'qa';

export const environment = {
    version: VERSION,
    production: false,
    baseUrl: `https://core-${VERSION}.givingandvolunteering.com/api/client-configuration-management`,
    apiKey: 'a1d063f3fae7e67f9fb38fbd59ab8c78',
    cognitoUserPoolId: 'us-east-2_2Z0D7fop5',
    cognitoAppClientId: 'a6575p1gsb9supcnh1oadjrcn',
    cognitoAppClientSecret: ''
}