import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './shared/components/login/login.component';
import { AuthGuard } from './shared/utils/auth.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'hud', loadChildren: () => import('./hud/hud.module').then(mod => mod.HudModule), canActivate: [AuthGuard] },
  
  { path: '', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
