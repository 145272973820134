import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HudLayoutComponent } from './hud-layout/hud-layout.component';
import { ClientSetupComponent } from './client-setup/client-setup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportsComponent } from './reports/reports.component';

import { AuthGuard } from '../shared/utils/auth.guard';
import { ClientSetupWizardComponent } from './client-setup/client-setup-wizard/client-setup-wizard.component';

const routes: Routes = [
  {
    path: 'hud',
    component: HudLayoutComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'client-setup', component: ClientSetupComponent, canActivate: [AuthGuard] },
      { path: 'client-setup/new', component: ClientSetupWizardComponent, canActivate: [AuthGuard] },
      { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] }
    ]
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HudRoutingModule { }
