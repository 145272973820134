import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-hud-layout',
  templateUrl: './hud-layout.component.html',
  styleUrls: ['./hud-layout.component.css']
})
export class HudLayoutComponent {

  constructor(private loginService: LoginService, private router: Router) {}

  doLogout() {
    this.loginService.logout();
    this.router.navigate(['/login']);
  }

}
