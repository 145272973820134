<p-messages></p-messages>
<div class="extra-controls grid w-full p-4 text-left">
    <div>
        <p-button label="Exit New Client Setup" styleClass="p-2 text-xs" (click)="exitWizard()" icon="pi pi-arrow-left"></p-button>
    </div>
</div>
<div class="card p-4">
    <p-steps [model]="steps" [(activeIndex)]="activeIndex" [readonly]="true"></p-steps>
</div>

<div class="wizard-container w-full p-8" [ngSwitch]="activeIndex">
    <div class="wizard-step-0 w-full" *ngSwitchCase="0">
        <p-card>
            <ng-template pTemplate="title">
                {{ steps[activeIndex].label }}
            </ng-template>
            <ng-template pTemplate="subtitle">
                Enter the client information
            </ng-template>
            <ng-template pTemplate="content">
                <div class="p-fluid formgrid grid">
                    <div class="field mb-4">
                        <label for="clientname" class="block text-sm text-gray-600 mb-2">Client Name</label>
                        <input type="text" 
                                pInputText 
                                [(ngModel)]="payload.data.name" 
                                class="placeholder:italic placeholder:text-slate-400 block bg-white w-1/2 border border-slate-300 rounded-md py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm" 
                                #clientname="ngModel" 
                                id="clientname" 
                                required 
                                [ngClass]="{'ng-dirty': (clientname.dirty && clientname.invalid)}"> 
                        <small *ngIf="(clientname.dirty && clientname.invalid)" class="p-error mb-2 ml-2">Client name is required.</small>
                    </div>
                    <div class="field mb-4">
                        <label for="envcode" class="block text-sm text-gray-600 mb-2">Environment Code</label>
                        <input type="text" pInputText [(ngModel)]="payload.data.environment_code" class="placeholder:italic placeholder:text-slate-400 block bg-white w-1/2 border border-slate-300 rounded-md py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm" #envcode="ngModel" id="envcode" required [ngClass]="{'ng-dirty': (envcode.dirty && envcode.invalid)}"> 
                        <small *ngIf="(envcode.dirty && envcode.invalid)" class="p-error mb-2 ml-2">Environment code is required.</small>
                    </div>
                    <p-divider></p-divider>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <div class="grid grid-nogutter justify-content-end w-full">
                    <div class="text-right">
                        <p-button label="Next" (click)="nextPage($event)" icon="pi pi-angle-right" iconPos="right" class="text-right" styleClass="p-button-sm" [disabled]="!(payload.data.name && payload.data.environment_code)" [ngClass]="{'disabled': !(payload.data.name && payload.data.environment_code)}"></p-button>
                    </div>
                </div>
            </ng-template>
        </p-card>
    </div>

    <div class="wizard-step-1 w-full" *ngSwitchCase="1">
        <p-card>
            <ng-template pTemplate="title">
                {{ steps[activeIndex].label }}
            </ng-template>
            <ng-template pTemplate="subtitle">
                Add client companies
            </ng-template>
            <ng-template pTemplate="content">
                <div *ngFor="let comp of payload.data.companies; index as i" class="p-fluid formgrid grid">
                    <div class="field mb-4">
                        <label for="companyname{{i}}" class="block text-sm text-gray-600 mb-2">Company Name</label>
                        <input #companyname="ngModel" id="companyname{{i}}" type="text" required pInputText [(ngModel)]="payload.data.companies[i].name" class="placeholder:italic placeholder:text-slate-400 block bg-white w-1/2 border border-slate-300 rounded-md py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm" [ngClass]="{'ng-dirty': (companyname.dirty && companyname.invalid)}"> 
                        <small *ngIf="(companyname.dirty && companyname.invalid)" class="p-error">Company name is required.</small>
                    </div>
                    <div class="field mb-4">
                        <label for="compcode{{i}}" class="block text-sm text-gray-600 mb-2">Company Code</label>
                        <input #compcode="ngModel" id="compcode{{i}}" type="text" required pInputText [(ngModel)]="payload.data.companies[i].company_code" class="placeholder:italic placeholder:text-slate-400 block bg-white w-1/2 border border-slate-300 rounded-md py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm" [ngClass]="{'ng-dirty': (compcode.dirty && compcode.invalid)}"> 
                        <small *ngIf="(compcode.dirty && compcode.invalid)" class="p-error">Company code is required.</small>
                    </div>
                    <div class="text-right" *ngIf="i > 0">
                        <button pButton label="Remove" (click)="removeCompany(i)" icon="pi pi-times" class="p-button-rounded p-button-danger text-xs remove-button"></button>
                    </div>
                    <p-divider></p-divider>
                </div>
                <div class="p-fluid grid w-1/4">
                    <p-button label="Add Company" (click)="addCompany()" icon="pi pi-plus" styleClass="p-button-sm text-sm"></p-button>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <div class="grid grid-cols-2 grid-nogutter justify-content-end w-full">
                    <div class=""><p-button label="Back" (click)="prevPage()" icon="pi pi-angle-left" styleClass="p-button-sm"></p-button></div>
                    <div class="text-right"><p-button label="Next" (click)="nextPage($event)" icon="pi pi-angle-right" class="" iconPos="right" styleClass="p-button-sm" [disabled]="!companiesValid(payload.data.companies)"  [ngClass]="{'disabled': !companiesValid(payload.data.companies)}"></p-button></div>
                </div>
            </ng-template>
        </p-card>
    </div>

    <div class="wizard-step-2 w-full" *ngSwitchCase="2">
        <p-card>
            <ng-template pTemplate="title">
                {{ steps[activeIndex].label }}
            </ng-template>
            <ng-template pTemplate="subtitle">
                Add payroll profiles
            </ng-template>
            <ng-template pTemplate="content">
                <div *ngFor="let pp of payload.data.grouppayrollprofiles; index as j" class="p-fluid formgrid grid">
                    <div class="field mb-4">
                        <label for="paygroup{{j}}" class="block text-sm text-gray-600 mb-2">Pay Group</label>
                        <input #paygroup="ngModel" id="paygroup{{j}}" type="text" required pInputText [(ngModel)]="payload.data.grouppayrollprofiles[j].pay_group" class="placeholder:italic placeholder:text-slate-400 block bg-white w-1/2 border border-slate-300 rounded-md py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm" [ngClass]="{'ng-dirty': (paygroup.dirty && paygroup.invalid)}"> 
                        <small *ngIf="(paygroup.dirty && paygroup.invalid)" class="p-error">Pay Group is required.</small>
                    </div>
                    <div class="field mb-4">
                        <label for="payfreq{{j}}" class="block text-sm text-gray-600 mb-2">Pay Frequency</label>
                        <input #payfreq="ngModel" id="payfreq{{j}}" type="text" required pInputText [(ngModel)]="payload.data.grouppayrollprofiles[j].pay_frequency" class="placeholder:italic placeholder:text-slate-400 block bg-white w-1/2 border border-slate-300 rounded-md py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm" [ngClass]="{'ng-dirty': (payfreq.dirty && payfreq.invalid)}"> 
                        <small *ngIf="(payfreq.dirty && payfreq.invalid)" class="p-error">Pay Frequency is required.</small>
                    </div>
                    <div class="field mb-4 w-1/3">
                        <label for="dedtype{{j}}" class="block text-sm text-gray-600 mb-2">Deduction Type</label>
                        <p-dropdown id="dedtype{{j}}" class="block text-sm text-gray-600 mb-2" [options]="deduction_types" [(ngModel)]="payload.data.grouppayrollprofiles[j].deduction_type"></p-dropdown>
                    </div>
                    <div class="field mb-4 w-1/3" *ngIf="payload.data.grouppayrollprofiles[j].deduction_type === 'PERIODIC'">
                        <label for="dedinterval{{j}}" class="block text-sm text-gray-600 mb-2">Deduction Interval</label>
                        <input id="dedinterval{{j}}" type="number" pInputText [(ngModel)]="payload.data.grouppayrollprofiles[j].deduction_interval" class="placeholder:italic placeholder:text-slate-400 block bg-white w-1/2 border border-slate-300 rounded-md py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"> 
                    </div>
                    <div class="field mb-4 w-1/3" *ngIf="payload.data.grouppayrollprofiles[j].deduction_type === 'FIXED'">
                        <label for="fixeddates{{j}}" class="block text-sm text-gray-600 mb-2">Deduction Dates</label>
                        <p-multiSelect id="fixeddates{{j}}" [options]="deduction_dates" [(ngModel)]="payload.data.grouppayrollprofiles[j].deduction_dates"></p-multiSelect>
                    </div>
                    <div class="field mb-4 w-1/3">
                        <label for="datestart{{j}}" class="block text-sm text-gray-600 mb-2">Start Date</label>
                        <p-calendar id="datestart{{j}}" dateFormat="yy-mm-dd" [(ngModel)]="payload.data.grouppayrollprofiles[j].date_holder" (ngModelChange)="updateProfileDates(j)"></p-calendar>
                    </div>
                    <div class="text-right" *ngIf="j > 0">
                        <button pButton label="Remove" (click)="removePayrollProfile(j)" icon="pi pi-times" class="p-button-rounded p-button-danger text-xs remove-button"></button>
                    </div>
                    <p-divider></p-divider>
                </div>
                <div class="p-fluid grid w-1/4">
                    <p-button label="Add Payroll Profile" (click)="addPayrollProfile()" icon="pi pi-plus" styleClass="p-button-sm text-sm"></p-button>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <div class="grid grid-cols-2 grid-nogutter justify-content-end w-full">
                    <div class=""><p-button label="Back" (click)="prevPage()" icon="pi pi-angle-left" styleClass="p-button-sm"></p-button></div>
                    <div class="text-right"><p-button label="Next" (click)="nextPage($event)" icon="pi pi-angle-right" class="" styleClass="p-button-sm" iconPos="right" [disabled]="!payrollProfilesValid(payload.data.grouppayrollprofiles)" [ngClass]="{'disabled': !payrollProfilesValid(payload.data.grouppayrollprofiles)}"></p-button></div>
                </div>
            </ng-template>
        </p-card>
    </div>

    <div class="wizard-step-3 w-full" *ngSwitchCase="3">
        <p-card>
            <ng-template pTemplate="title">
                {{ steps[activeIndex].label }}
            </ng-template>
            <ng-template pTemplate="subtitle">
                Confirm and Submit
            </ng-template>
            <ng-template pTemplate="content">
                <div class="p-fluid formgrid grid">
                    <div class="field">
                        <app-donor-group-details [donorGroup]="payload.data" class=" w-2/3"></app-donor-group-details>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <div class="grid grid-cols-2 grid-nogutter justify-content-end w-full">
                    <div class=""><p-button label="Back" (click)="prevPage()" icon="pi pi-angle-left" styleClass="p-button-sm"></p-button></div>
                    <div class="text-right"><p-button label="Confirm & Submit" (click)="doConfirmThenSubmit()" class="" styleClass="p-button-sm mb-2" iconPos="right" [ngClass]="{'p-disabled': isFetching}"></p-button></div>
                </div>
            </ng-template>
        </p-card>
    </div>

    <div class="wizard-step-3 w-full" *ngSwitchCase="4">
        <p-card>
            <ng-template pTemplate="title">
                {{ steps[activeIndex].label }}
            </ng-template>
            <ng-template pTemplate="subtitle">
                Hurray!
            </ng-template>
            <ng-template pTemplate="content">
                <div class="p-fluid formgrid grid">
                    <div class="field">
                        <p class="text-center">
                            <img class="m-auto p-2 shadow mb-6" title="victory" src="assets/img/rocky_balboa.gif"/>
                        </p>
                        <p class="text-center">Give yourself a pat on the back!</p>
                        <p class="text-center">Your work here is complete!</p>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <div class="grid grid-nogutter justify-content-end w-full">
                    <div class="text-center"><p-button label="All Done!" (click)="exitWizard()" class="" styleClass="p-button"></p-button></div>
                </div>
            </ng-template>
        </p-card>
    </div>

</div>